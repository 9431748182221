var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ek-dialog',{ref:"dialog",attrs:{"btnText":"مجيب جديد","title":'اضافة مجيب اسئلة',"placeholder":"ابحث عن مجيب محدد"},on:{"ok":_vm.submit,"search":_vm.setSearch,"close":_vm.reset},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('validationObserver',{ref:"questionAnswerForm"},[_c('b-form',[_c('ek-input-text',{attrs:{"placeholder":"ادخل الاسم واالكنية","name":"الاسم والكنية","label":"الاسم والكنية","rules":[
                            {
                                type: 'required',
                                message: 'الاسم مطلوب'
                            }
                        ]},model:{value:(_vm.dtoQuestion.name),callback:function ($$v) {_vm.$set(_vm.dtoQuestion, "name", $$v)},expression:"dtoQuestion.name"}}),_c('ek-input-text',{attrs:{"placeholder":"ادخل  البريد الالكتروني","name":"البريد الالكتروني","label":"البريد الالكتروني","rules":[
                            { type: 'required', message: 'الاسم مطلوب' },
                            {
                                type: 'email',
                                message: 'يرجى ادخال بريد الكتروني صحيح'
                            }
                        ]},model:{value:(_vm.dtoQuestion.email),callback:function ($$v) {_vm.$set(_vm.dtoQuestion, "email", $$v)},expression:"dtoQuestion.email"}}),_c('ek-input-text',{attrs:{"placeholder":"ادخل  كلمة السر","name":"كلمة السر","label":"كلمة السر","rules":[
                            {
                                type: 'min:8',
                                message:
                                    'كلمة المرور يجب ان تكون اطول من 8 محارف'
                            }
                        ]},model:{value:(_vm.dtoQuestion.password),callback:function ($$v) {_vm.$set(_vm.dtoQuestion, "password", $$v)},expression:"dtoQuestion.password"}}),_c('ek-input-text',{attrs:{"placeholder":"ادخل  رقم الهاتف","name":"رقم الهاتف","label":"رقم الهاتف","rules":[
                            { type: 'required', message: 'رقم مطلوب' },
                            {
                                type: 'mobile',
                                message: 'رقم الهاتف غير صالح'
                            }
                        ]},model:{value:(_vm.dtoQuestion.phoneNumber),callback:function ($$v) {_vm.$set(_vm.dtoQuestion, "phoneNumber", $$v)},expression:"dtoQuestion.phoneNumber"}}),_c('ek-date-picker',{attrs:{"placeholder":"ادخل  تاريخ الميلاد","name":"تاريخ الميلاد","label":"تاريخ الميلاد","rules":[
                            {
                                type: 'required',
                                message: 'تاريخ الميلاد مطلوب'
                            }
                        ]},model:{value:(_vm.dtoQuestion.dateCreated),callback:function ($$v) {_vm.$set(_vm.dtoQuestion, "dateCreated", $$v)},expression:"dtoQuestion.dateCreated"}})],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }