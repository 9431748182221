<template>
    <div>
        <ek-dialog
            ref="dialog"
            @ok="submit"
            @search="setSearch"
            @close="reset"
            btnText="مجيب جديد"
            :title="'اضافة مجيب اسئلة'"
            placeholder="ابحث عن مجيب محدد"
        >
            <template #body>
                <validationObserver ref="questionAnswerForm">
                    <b-form>
                        <ek-input-text
                            v-model="dtoQuestion.name"
                            placeholder="ادخل الاسم واالكنية"
                            name="الاسم والكنية"
                            label="الاسم والكنية"
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'الاسم مطلوب'
                                }
                            ]"
                        >
                        </ek-input-text>
                        <ek-input-text
                            v-model="dtoQuestion.email"
                            placeholder="ادخل  البريد الالكتروني"
                            name="البريد الالكتروني"
                            label="البريد الالكتروني"
                            :rules="[
                                { type: 'required', message: 'الاسم مطلوب' },
                                {
                                    type: 'email',
                                    message: 'يرجى ادخال بريد الكتروني صحيح'
                                }
                            ]"
                        >
                        </ek-input-text>
                        <ek-input-text
                            v-model="dtoQuestion.password"
                            placeholder="ادخل  كلمة السر"
                            name="كلمة السر"
                            label="كلمة السر"
                            :rules="[
                                {
                                    type: 'min:8',
                                    message:
                                        'كلمة المرور يجب ان تكون اطول من 8 محارف'
                                }
                            ]"
                        >
                        </ek-input-text>
                        <ek-input-text
                            v-model="dtoQuestion.phoneNumber"
                            placeholder="ادخل  رقم الهاتف"
                            name="رقم الهاتف"
                            label="رقم الهاتف"
                            :rules="[
                                { type: 'required', message: 'رقم مطلوب' },
                                {
                                    type: 'mobile',
                                    message: 'رقم الهاتف غير صالح'
                                }
                            ]"
                        >
                        </ek-input-text>
                        <ek-date-picker
                            v-model="dtoQuestion.dateCreated"
                            placeholder="ادخل  تاريخ الميلاد"
                            name="تاريخ الميلاد"
                            label="تاريخ الميلاد"
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'تاريخ الميلاد مطلوب'
                                }
                            ]"
                        >
                        </ek-date-picker>
                    </b-form>
                </validationObserver>
            </template>
        </ek-dialog>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    computed: {
        ...mapState({
            dtoQuestion: state => state.questionsAnswerer.dtoQuestion
        })
    },
    methods: {
        submit() {
            this.$refs["questionAnswerForm"].validate().then(suc => {
                if (suc) {
                    this.addQuestionsAnswerer(this.dtoQuestion);
                    this.$store.commit("RESET_DTO");
                    this.$refs.dialog.close();
                }
            });
        },
        setSearch(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: ["name", "answersCount"],
                query
            });
        },
        reset() {
            this.$refs.questionAnswerForm.reset();
            this.$store.commit("RESET_DTO");
        },
        ...mapActions(["addQuestionsAnswerer"])
    }
};
</script>

<style></style>
